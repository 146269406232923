import {stringify} from 'himalaya';

export default function render({element, config}) {
    setTimeout(() => {
        const eventName = 'bfp-custom-embed:init';
        let event;
        try {
            event = new Event(eventName);
        } catch (exc) {
            event = document.createEvent('Event');
            event.initEvent(eventName, true, true);
        }
        document.dispatchEvent(event);
    }, 0);

    // Non-SSR rendering places text/config in the container
    //   <script type="text/x-config">...</script>
    // if its the only thing it means SSR didn't happen
    const onlyConfig = element.childElementCount === 1 && element.firstElementChild.type === 'text/x-config';
    if (element.childElementCount > 0 && !onlyConfig) {
        // SSR already there
        return;
    }

    element.innerHTML = stringify(config.data.web);
}
